import { setCookie, getCookie } from "../../utils/auth";
import { goLogin } from '@/api';
import Register from './register.vue';
export default {
  data() {
    return {
      selIndex: 0,
      account: getCookie("account") || "",
      password: getCookie("password") || ""
    };
  },
  methods: {
    goFw() {
      window.open("https://u7w6n5.com/chat/text/chat_14Zgck.html");
    },
    goBack() {
      this.$router.replace("/");
      //   $router.go(-1)
    },
    choseBar(val) {
      this.selIndex = val;
    },
    login() {
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      goLogin({
        account: this.account,
        password: this.password
      }).then(res => {
        this.$closeToast();
        setCookie('token', res.data.token);
        const {
          redirect
        } = this.$route.query;
        this.$store.dispatch('getUserInfo').then(() => {
          if (redirect) {
            this.$router.replace(redirect);
          } else {
            this.$router.replace("/");
          }

          //   window.location.href = "./index.html"
        });
      });
    }
  },
  components: {
    Register
  }
};