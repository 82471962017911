import { register, getRegusterCode } from "@/api";
import { setCookie } from "@/utils/auth";
export default {
  mounted() {
    if (this.$route.query.uid) {
      this.top = this.$route.query.uid;
    }
    this.pwd = "";
    this.phone = "";
    this.changeCode();
  },
  data() {
    return {
      code: "",
      phone: "",
      pwd: "",
      pwd2: "",
      top: "",
      verify_code: ""
    };
  },
  methods: {
    changeCode() {
      getRegusterCode().then(res => {
        this.code = res.data.code;
      });
    },
    goRegister() {
      if (!this.phone) {
        this.$toast("请输入手机号");
        return;
      }
      if (!this.pwd || !this.pwd2) {
        this.$toast("请输入密码");
        return;
      }
      if (this.pwd !== this.pwd2) {
        this.$toast("两次密码输入不一致");
        return;
      }
      if (!this.verify_code) {
        this.$toast("请输入验证码");
        return;
      }
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      register({
        phone: this.phone,
        pwd: this.pwd,
        pwd2: this.pwd2,
        top: this.top,
        verify_code: this.verify_code
      }).then(res => {
        this.$closeToast();
        this.$toast(res.msg);
        setCookie("token", res.data.token);
        this.$store.dispatch("getUserInfo");
        this.$router.replace("/");
        return;
      });
    }
  }
};